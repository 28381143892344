<template>
  <b-overlay :show="showLoading" spinner-variant="primary">
    <b-card>
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Nom d'utilisateur" label-for="account-username">
              <b-form-input disabled v-model="optionsLocalUsername" placeholder="Nom d'utilisateur" name="username" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Mot de passe" label-for="account-username">
              <b-form-input disabled v-model="optionsLocalUsername" placeholder="Mot de passe" name="username" />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Prénom" label-for="name">
              <b-form-input v-model="optionsLocal.firstName" id="name" placeholder="Nom complet"
                :state="isValidFirstName" />
              <b-form-invalid-feedback>
                Nom complet est un champs obligatoire
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Nom" label-for="name">
              <b-form-input v-model="optionsLocal.lastName" id="name" placeholder="Nom complet"
                :state="isValidLastName" />
              <b-form-invalid-feedback>
                Nom complet est un champs obligatoire
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="E-mail" label-for="email">
              <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" :state="isValidEmail" />
              <b-form-invalid-feedback>
                E-mail doit suivi la format suivant : mailprefix@example.com
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Téléphone" label-for="phone">
              <b-form-input v-model="optionsLocal.phoneNo" id="phone" placeholder="Téléphone" :state="isValidPhone" />
              <b-form-invalid-feedback>
                Téléphone est un champs obligatoire
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Adresse" label-for="address">
              <b-form-input v-model="optionsLocal.address" name="address" placeholder="Adresse" />
            </b-form-group>
          </b-col>

          <!-- buttons -->
          <b-col cols="12" class="text-center">
            <b-button variant="success" class="mt-1 mr-1" @click="createEditor()"
              :disabled="!isValidEmail || !isValidFirstName || !isValidLastName || !isValidPhone">
              Créer utilisateur
            </b-button>
            <b-button @click="resetForm" variant="outline-secondary" class="mt-1">
              Réinitialiser
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      showLoading: false,
      optionsLocal: {
        address: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        username: ""
      }
    }
  },
  computed: {
    optionsLocalUsername() {
      return this.optionsLocal.email.substring(0, this.optionsLocal.email.indexOf("@")) + this.$store.state["app-userdata"].AppActiveUser.username.substr(-5);
    },
    isValidEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.optionsLocal.email);
    },
    isValidFirstName() {
      return this.optionsLocal.firstName.trim() != "";
    },
    isValidLastName() {
      return this.optionsLocal.lastName.trim() != "";
    },
    isValidPhone() {
      return this.optionsLocal.phoneNo.trim() != "";
    }
  },
  created() {
    this.optionsLocal.address = JSON.parse(JSON.stringify(this.$store.state["app-userdata"].AppActiveUser)).address;
  },
  methods: {
    resetForm() {
      this.optionsLocal = {
        address: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        username: ""
      };
      this.optionsLocal.address = JSON.parse(JSON.stringify(this.$store.state["app-userdata"].AppActiveUser)).address;
    },
    createEditor() {
      this.showLoading = true;
      this.optionsLocal.username = this.optionsLocalUsername;
      this.$http.post("pharmacist_assistant", this.optionsLocal).then(() => {
        this.showLoading = false;
        this.$router.push({ name: 'sub-accounts' })
        this.showToast('success', 'CheckIcon', 'Mise à jour', "Nouvel utilisateur a été créé avec succès");
        this.resetForm();
      }).catch(() => {
        this.showLoading = false;
      });
    },
    showToast(variant, icon, title, text) {
      this.$toast.clear();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon,
          text: text,
          variant,
        },
      });
    },
  },

}
</script>
